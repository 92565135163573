import * as S from './GameMachine.styles';
import { Spinner } from './Spinner';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { useMemo, useRef } from 'react';
import { getRandomNumber, numberWithSpaces } from '../../../../utils/number';
import { isShowTermsAndPrivacy } from '../../../../utils/routes';
import BeGambleAware from '../../../../assets/gameMachine/BeGambleAware.svg';

export function GameMachine() {
  const { onFinish, config, roundNumber, roundWinAmount, onStart, isRolling, isWinningRound } = useGameState();
  let matches: number[] = [];
  let _spinner1 = useRef<Spinner | null>(null);
  let _spinner2 = useRef<Spinner | null>(null);
  let _spinner3 = useRef<Spinner | null>(null);

  const finishHandler = (value: number) => {
    matches.push(value);
    if (matches.length === 3) {
      onFinish(matches);
    }
  };

  const handleClick = () => {
    onStart();
    matches = [];
    if (_spinner1 && _spinner2 && _spinner3) {
      _spinner1.current?.spin();
      _spinner2.current?.spin();
      _spinner3.current?.spin();
    }

    // facebook and google Contact event
    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      fbq('track', 'Contact');
    }
    //@ts-ignore
    if (window.gtag) {
      //@ts-ignore
      gtag('event', 'Contact');
    }
  };

  const SPINNER_1 = useMemo(() => {
    const finishNumber = config.slotConfig[roundNumber - 1] ? config.slotConfig[roundNumber - 1][0] : getRandomNumber();

    return (
      <Spinner
        onFinish={finishHandler}
        ref={_spinner1}
        finishNumber={finishNumber}
        timer={1000}
        isWinningRound={isWinningRound}
      />
    );
  }, [roundNumber, config, isWinningRound]);

  const SPINNER_2 = useMemo(() => {
    const finishNumber = config.slotConfig[roundNumber - 1] ? config.slotConfig[roundNumber - 1][1] : getRandomNumber();

    return (
      <Spinner
        onFinish={finishHandler}
        ref={_spinner2}
        finishNumber={finishNumber}
        timer={1400}
        isWinningRound={isWinningRound}
      />
    );
  }, [roundNumber, config, isWinningRound]);

  const SPINNER_3 = useMemo(() => {
    const finishNumber = config.slotConfig[roundNumber - 1] ? config.slotConfig[roundNumber - 1][2] : getRandomNumber();

    return (
      <Spinner
        onFinish={finishHandler}
        ref={_spinner3}
        finishNumber={finishNumber}
        timer={2200}
        isWinningRound={isWinningRound}
      />
    );
  }, [roundNumber, config, isWinningRound]);

  return (
    <div>
      <S.GameMachine />
      <S.WinAmountWrapper $isWinningRound={isWinningRound}>
        <S.WinAmount>{roundWinAmount && numberWithSpaces(roundWinAmount)}</S.WinAmount>
      </S.WinAmountWrapper>
      <S.SpinnerWrapper $isWithShadow={isWinningRound && !isRolling}>
        <S.SpinnerContainer>
          {SPINNER_1}
          {SPINNER_2}
          {SPINNER_3}
        </S.SpinnerContainer>
      </S.SpinnerWrapper>
      <S.SpinButton disabled={isRolling} onClick={isRolling ? undefined : handleClick}>
        <S.ButtonText>{config.spinButtonText}</S.ButtonText>
      </S.SpinButton>
      {isShowTermsAndPrivacy && (
        <S.TermsWrapper>
          <S.TermsText>
            This website is designed exclusively for entertainment purposes. The games available on this site do not
            involve real money, bets, or winnings. All activities on this website are purely for fun and amusement. It's
            important to note that this website is intended for adult users (18+) and does not provide opportunities for
            "real money gambling" or the chance to win actual money or prizes. If you require assistance in managing
            your gambling activity responsibly, consider seeking support from{' '}
            <a target="_blank" href="https://www.kethea.gr/en/ ">
              gambling therapy services
            </a>
            . Look of Era maintains no responsibility for any losses, damages, or negative consequences that may arise
            from the use or misuse of the information or simulator provided on our website. Users are advised to engage
            responsibly, acknowledging the potential risks involved, including the development of addictive behavior. It
            is essential to approach the simulator with caution and awareness of its simulated nature, ensuring a
            mindful and enjoyable experience.
            <br />
            Reliability-Security-Legality
            <br />
            We start with a basic and inviolable rule. The live casino you choose must operate with all legitimacy to
            keep you and your money Safe. We check the blacklist every day, which means that here you will find only the
            best live licensed online casinos. After you see that it is 100% legal, you pass to the next stage which is
            registration.
            <br />
            Casino security assessment criteria
            <br />
            We use two basic methods of finding and evaluating online casinos. Initially, using years of experience and
            knowledge we search and analyze online casinos, betting and or poker rooms. After we have assessed the
            credibility of a casino by focusing on potential licenses, financial stability and reputation, we check the
            hard data. Because using algorithms to assess popularity levels, Software Quality, Payment Systems and so
            on. It is useful but not enough for a complete picture. But if combined with independent in-depth research,
            then the combination can yield very detailed analyses.
            <br />
            Variety, security & speed of payment methods
            <br />
            The last, but equally key piece for the best live online casinos is variety. Both in the payment methods and
            in the list of live games. The many trading modes available "untie the hands of the players". It is
            extremely easy for customers of an online casino live to be able to deposit with both prepaid cards
            (paysafecard) and e-wallets (Skrill, Neteller) and bank cards (Visa, Mastercard, etc.) or via bank transfer.
            The same is true of modes of Ascension.
          </S.TermsText>
          <img src={BeGambleAware} alt="BeGambleAware"></img>
          <S.LinksWrapper>
            <S.Link
              onClick={() => {
                window.open('/privacy.html', '_blank');
              }}
            >
              PRIVACY POLICY
            </S.Link>

            <S.Link
              onClick={() => {
                window.open('/terms.html', '_blank');
              }}
            >
              TERMS OF USE
            </S.Link>
          </S.LinksWrapper>
          <S.TermsBottomText>Copyright © 2024 LooksOfEra</S.TermsBottomText>
        </S.TermsWrapper>
      )}
    </div>
  );
}
